








































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { FormDefinition, FormSubmissionValidationError, FormField, FormValue } from 'client-website-ts-library/types/Forms';
import {
  ServiceManager,
  ServiceType,
  API,
  Config,
  Logger,
  LogLevel,
  ReCaptchaService,
} from 'client-website-ts-library/services';
import { Context } from 'client-website-ts-library/types';
import { PDFFieldData } from 'client-website-ts-library/types/Forms/FieldTypes';
import { IRequestProgressDelegate } from 'client-website-ts-library/services/API/IRequestProgressDelegate';

import Section from './Section.vue';
import Loader from '../UI/Loader.vue';

@Component({
  components: {
    Section,
    Loader,
  },
})
export default class SteppedForm extends Vue implements IRequestProgressDelegate {
  currentStep = 0

  validatingStep = false

  @Prop({ default: false })
  private readonly fullHeight!: boolean;

  @Prop({ default: () => ({ Items: [] }) })
  private readonly context!: Context;

  @Prop()
  private readonly type!: string;

  @Prop()
  private readonly submitButtonColour!: string;

  @Prop()
  private readonly submitButtonBackgroundColour!: string;

  @Prop()
  private readonly submitButtonClass!: string;

  @Prop()
  private readonly autosaveNamespace!: string;

  @Prop()
  private readonly enableAutosave!: boolean;

  @Prop({ default: 'var(--form-field-bg)' })
  private readonly fieldBg!: string;

  @Prop({ default: 'var(--form-field-colour)' })
  private readonly fieldColour!: string;

  @Prop({ default: 'var(--form-label-colour)' })
  private readonly labelColour!: string;

  @Prop({ default: 'var(--form-field-border-colour)' })
  private readonly borderColour!: string;

  @Prop({ default: 'var(--form-label-focused-bg)' })
  private readonly labelFocusedBg!: string;

  @Prop({ default: 'var(--form-label-focused-colour)' })
  private readonly labelFocusedColour!: string;

  private ctx: Context = this.context;

  private errored = false;

  private submitted = false;

  private errors: FormSubmissionValidationError[] = [];

  private loading = true;

  private definition: FormDefinition | null = null;

  private submitPercent = 0;

  private recaptchaService = ServiceManager.Require(ServiceType.ReCaptcha) as ReCaptchaService;

  onProgress(progress: number, total: number) {
    this.submitPercent = Math.round((progress / total) * 100);

    console.log(this.submitPercent);
  }

  mounted() {
    if (this.ctx === null) {
      this.ctx = {
        Items: [],
        ClientWebsiteId: Config.Website.Id,
      };
    }

    if (this.ctx.ClientWebsiteId === undefined) {
      this.ctx.ClientWebsiteId = Config.Website.Id;
    }

    API.Forms.GetForm(this.type, this.context).then((def) => {
      this.loading = false;
      this.definition = def;
    }).catch(() => {
      this.errored = true;
    });
  }

  private handleFieldInput(fieldDef: FormField): void {
    this.errors = this.errors.filter((err) => err.Key !== fieldDef.Key);
  }

  submitForm(): void {
    if (this.loading) return;

    this.loading = true;

    this.errors = [];

    this.collectData(true).then((data) => {
      const normalisedData = SteppedForm.normaliseData(data);

      this.loading = false;

      API.Forms.SubmitForm(this.type, this.context, normalisedData).then((result) => {
        this.loading = false;

        if (!result.Valid) {
          // Reset ReCaptcha
          this.resetRecaptcha();

          // Update errors
          this.errors = result.ValidationState.Errors;
        } else if (result.Submitted) {
          // Inform the user that the form was submitted
          this.submitted = true;

          this.$emit('submitted');
        }
      }).catch(() => {
        // Reset ReCaptcha
        this.resetRecaptcha();

        // Inform the user that there was an error
        this.errored = true;
      });
    });
  }

  private static normaliseData(data: FormValue[]): FormValue[] {
    const newValues: FormValue[] = [];

    data.forEach((entry) => {
      if (entry.Value instanceof Array) {
        if (entry.Value.length) {
          newValues.push({
            Key: `${entry.Key}_length`,
            Value: entry.Value.length,
          });

          for (let i = 0; i < entry.Value.length; i += 1) {
            newValues.push({
              Key: `${entry.Key}_${i}`,
              Value: entry.Value[i],
            });
          }
        }
      } else {
        newValues.push(entry);
      }
    });

    return newValues;
  }

  private collectData(includeRecaptcha: boolean): Promise<FormValue[]> {
    return new Promise((resolve, reject) => {
      const data: FormValue[] = [];

      // Loop through each form secction and collect the data from it
      this.definition!.Sections.forEach((section) => {
        try {
          const sectionComponent = (this.$refs[section.SectionId] as Vue[])[0] as Section;
          data.push(...sectionComponent.collectData());
        } catch (ex) { /* */ }
      });

      // If we also need to submit the ReCaptcha response, make sure we call getResponse on the ReCaptcha component
      if (includeRecaptcha) {
        this.getRecaptchaResponse().then((recaptchaResponse: string) => {
          Logger.Log(LogLevel.Debug, '[Form]', 'Got ReCaptcha response');

          data.push({
            Key: '_recaptcha',
            Value: recaptchaResponse,
          });

          resolve(data);
        }).catch(reject);

        return;
      }

      // If we don't need ReCaptcha, resolve straight away
      resolve(data);
    });
  }

  private resetRecaptcha(): void {
    this.recaptchaService.Reset();
  }

  private getRecaptchaResponse(): Promise<string> {
    return this.recaptchaService.GetResponse();
  }

  goForward() {
    if (this.validatingStep) return;

    this.validatingStep = true;

    this.errors = [];

    this.collectData(false).then((data) => {
      const normalisedData = SteppedForm.normaliseData(data);

      API.Forms.ValidateSection(this.type, this.context, normalisedData, this.currentStep).then((result) => {
        this.loading = false;

        if (!result.Valid) {
          // Update errors
          this.errors = result.Errors;
          this.validatingStep = false;
        } else {
          // Move to next step
          this.validatingStep = false;
          this.currentStep += 1;
        }
      }).catch(() => {
        // Inform the user that there was an error
        this.validatingStep = false;
        this.errored = true;
      });
    });
  }

  goBack() {
    this.currentStep -= 1;
  }
}
