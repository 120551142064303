











































import Masthead from '@/components/Common/Masthead.vue';
import { RouteMeta, View } from 'client-website-ts-library/plugins';
import { API, Config } from 'client-website-ts-library/services';
import { Context, Profile } from 'client-website-ts-library/types';
import { ContextItemType } from 'client-website-ts-library/types/Context';
import { Component, Mixins } from 'vue-property-decorator';

import SteppedForm from '../components/Forms/SteppedForm.vue';

@Component({
  components: {
    Masthead,
    SteppedForm,
  },
})
export default class Survey extends Mixins(View) {
  private profile: Profile | null = null;

  private formData: Context | null = null;

  private submitted = false;

  resolveId() {
    const { id } = this.$route.params;

    if (id.length === 36) {
      // If the ID is 36 long we'll treat it as a guid
      this.updateAgent(id);
    } else {
      API.Slugs.Resolve(id).then((resolved) => {
        if (resolved.Type === 'Profile') {
          this.updateAgent(resolved.Id);
        }
      });
    }
  }

  stripHtml(html: string): string {
    const el = document.createElement('div');

    el.innerHTML = html;

    return el.innerText;
  }

  head(): RouteMeta | null {
    if (this.profile === null) return null;

    let about = this.stripHtml(this.profile.AboutAgent);

    if (about.length > 156) {
      about = `${about.substring(0, 156)}...`;
    }

    return {
      title: `Get an Appraisal - ${this.profile.FullName} - ${this.profile.Position}`,
      description: about,
      image: this.profile.Photo ? this.profile.Photo.Preview.Url : undefined,
    };
  }

  updateAgent(id: string) {
    this.formData = null;

    API.Profiles.Get(id).then((profile) => {
      this.profile = profile;

      this.formData = {
        Items: [{
          Type: ContextItemType.EnquiryTargetProfile,
          Id: profile.Id,
        }],
        ClientWebsiteId: Config.Website.Id,
        WebsiteId: Config.Website.Settings!.WebsiteId,
        WebsiteLevel: Config.Website.Settings!.WebsiteLevel,
      };
    });
  }

  mounted() {
    this.resolveId();
    this.submitted = false;
  }
}
